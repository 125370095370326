import { invariantResponse } from '@epic-web/invariant'
import { PlusIcon } from '@heroicons/react/20/solid'
import {
	redirect,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Link, json, useLoaderData, useSearchParams } from '@remix-run/react'
import { type ColumnDef } from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { PiIdentificationCard } from 'react-icons/pi'
import { z } from 'zod'
import { zx } from 'zodix'
import GenericErrorBoundary from '#app/components/error-boundary'
import Hero from '#app/components/hero'
import { SearchQuery } from '#app/components/search/search-query'
import { Avatar, AvatarFallback, AvatarImage } from '#app/components/ui/avatar'
import { LinkButton } from '#app/components/ui/button'
import { DataTable } from '#app/components/ui/data-table'
import HeaderSimple from '#app/components/ui/header'
import { Label } from '#app/components/ui/label'
import { Switch } from '#app/components/ui/switch'
import { getUserId } from '#app/utils/auth.server'
import { useHints } from '#app/utils/client-hints'
import { getApiClient } from '#app/utils/service-api.server'
import { getInitials } from '#app/utils/string'
import { type Job } from '#types/api-client.types.gen'
import { jobOrderingSchema } from '#types/api-client.types.zod'
import { listJobs } from '#app/.server/api-client/sdk.gen'

const DEFAULT_PAGE_SIZE = 25

export const meta: MetaFunction = () => [{ title: 'Jobs | Moonhub' }]

// Extract the types for order_by and order_direction
type OrderByType = z.infer<typeof jobOrderingSchema.shape.order_by>
type OrderDirectionType = z.infer<
	typeof jobOrderingSchema.shape.order_direction
>

// Define the sort by schema with transformation and validation
const sortBySchema = z
	.string()
	.transform((value) => {
		const [order_by = 'created_at', order_direction = 'desc'] = value.split(
			'.',
		) as [OrderByType, OrderDirectionType]
		return { order_by, order_direction }
	})
	.refine(
		({ order_by, order_direction }) => {
			return (
				jobOrderingSchema.shape.order_by.safeParse(order_by).success &&
				jobOrderingSchema.shape.order_direction.safeParse(
					order_direction,
				).success
			)
		},
		{
			message: 'Invalid sort format or values.',
		},
	)

export async function loader({ request }: LoaderFunctionArgs) {
	try {
		const client = await getApiClient(request)
		const userId = await getUserId(request)

		const {
			page = 0,
			sort,
			owner,
			q: query,
		} = zx.parseQuery(request, {
			page: z.coerce.number().default(0),
			sort: sortBySchema.default('created_at.desc'),
			owner: z.string().optional().nullable(),
			q: z.string().optional().nullable(),
		})
		const { order_by, order_direction } = sort

		const { data: jobs } = await listJobs({
			client,
			body: {
				pagination: {
					page_size: DEFAULT_PAGE_SIZE,
					page,
				},
				ordering: [
					{
						order_by,
						order_direction,
					},
				],
				filters: {
					query,
					...(userId && !owner
						? {
								owner_or_team_member_ids: [userId],
							}
						: undefined),
				},
			},
		})
		invariantResponse(jobs?.entries && jobs?.metadata, "Couldn't load jobs")

		// redirect to the first page we have a page number and no results
		if (jobs.entries.length === 0 && page > 0) {
			return redirect('/app/jobs')
		}

		return json({
			jobs: jobs.entries,
			metadata: jobs.metadata,
		})
	} catch (error) {
		throw error
	}
}

export const columns: ColumnDef<Job>[] = [
	{
		accessorKey: 'name',
		header: 'Name',
		enableSorting: true,
		cell: ({ row }) => {
			// get the name and logo from the row
			const id = row.original.id
			const name = row.original.name

			return (
				<div className="flex items-center gap-x-2">
					<div className="flex flex-grow flex-col gap-y-0.5">
						<div className="flex flex-grow flex-row gap-x-3">
							<div className="flex flex-1">
								<Link
									className="flex flex-1"
									to={`/app/jobs/${id}`}
								>
									{name}
								</Link>
							</div>
						</div>
					</div>
				</div>
			)
		},
	},
	{
		accessorKey: 'job_title',
		header: 'Job Title',
		enableSorting: true,
		cell: ({ row }) => <div>{row.getValue('job_title') ?? '-'}</div>,
	},
	{
		accessorKey: 'company_name',
		header: 'Company',
		enableSorting: true,
		cell: ({ row }) => <div>{row.getValue('company_name') ?? '-'}</div>,
	},
	{
		accessorKey: 'owner_name',
		header: 'Owner',
		enableSorting: false,
		cell: ({ row }) => {
			// get the initials from the name
			const owner_name = row.original.owner_name ?? 'Me'
			const initials = getInitials(owner_name)

			return (
				<div className="flex items-center gap-x-2">
					<Avatar className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-gray-200 text-lg text-gray-600">
						{row.original.owner_picture && (
							<AvatarImage
								src={row.original.owner_picture}
								className="rounded-full"
							/>
						)}
						<AvatarFallback>{initials}</AvatarFallback>
					</Avatar>
					<div className="flex flex-grow flex-col gap-y-0.5">
						<div className="flex flex-grow flex-row gap-x-3">
							<div className="flex">{owner_name}</div>
						</div>
					</div>
				</div>
			)
		},
	},
	{
		accessorKey: 'team_members',
		header: 'Team',
		enableSorting: false,
		cell: ({ row }) => {
			if (
				!row.original.team_members ||
				row.original.team_members.length === 0
			) {
				return <div className="text-gray-300">-</div>
			}

			return (
				<div className="flex items-center">
					<dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
						<div className="flex -space-x-0.5">
							{row.original.team_members.map((member) => (
								<dd key={member.id}>
									<Avatar className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-gray-200 text-lg text-gray-600 ring-2 ring-white">
										{member.picture && (
											<AvatarImage
												src={member.picture}
												className="rounded-full"
											/>
										)}
										<AvatarFallback>
											{getInitials(member.name)}
										</AvatarFallback>
									</Avatar>
								</dd>
							))}
						</div>
					</dl>
				</div>
			)
		},
	},
	{
		accessorKey: 'status',
		header: 'Status',
		enableSorting: false,
		cell: ({ row }) => <div>{row.getValue('status')}</div>,
	},
	{
		accessorKey: 'created_at',
		header: 'Created Date',
		enableSorting: true,
		cell: function CellComponent({ row }) {
			const { timeZone } = useHints()
			const display = new Intl.DateTimeFormat(undefined, {
				timeZone,
				dateStyle: 'medium',
			}).format(new Date(row.getValue('created_at')))

			return <div>{display}</div>
		},
	},
	// {
	// 	id: 'actions',
	// 	enableHiding: false,
	// 	cell: () => {
	// 		return (
	// 			<Button variant="ghost" className="h-8 w-8 p-0">
	// 				<span className="sr-only">Open menu</span>
	// 				{/* <MoreHorizontal className="h-4 w-4" /> */}
	// 			</Button>
	// 		)
	// 	},
	// },
]

export default function JobsIndex() {
	const { jobs, metadata } = useLoaderData<typeof loader>()
	const [searchParams, setSearchParams] = useSearchParams()
	const [filterOwnJobs, setFilterOwnJobs] = useState<boolean>(
		searchParams.get('owner') === 'all' ? false : true,
	)

	useEffect(() => {
		const params = new URLSearchParams(searchParams)
		if (filterOwnJobs && params.has('owner')) {
			params.delete('owner')
			// update to first page
			if (params.has('page')) {
				params.delete('page')
			}
		} else if (!filterOwnJobs) {
			params.set('owner', 'all')
		}
		setSearchParams(params)
	}, [filterOwnJobs, setSearchParams, searchParams])

	return (
		<div className="flex h-full flex-1 flex-col gap-x-5">
			<HeaderSimple
				title="Jobs"
				subtitle={
					'Manage your jobs to organize sourcing and potential candidates.'
				}
				className="flex flex-none"
			>
				<div className="flex items-center gap-x-5">
					<div className="flex items-center gap-x-2">
						<Switch
							id="own-jobs"
							checked={filterOwnJobs}
							onCheckedChange={() =>
								setFilterOwnJobs(!filterOwnJobs)
							}
							className="data-[state=checked]:bg-primary"
						/>
						<Label htmlFor="own-jobs">View My Jobs</Label>
					</div>
					<LinkButton to={'new'} variant="default" size="sm">
						<PlusIcon className="h-4 w-4" />
						Add Job
					</LinkButton>
				</div>
			</HeaderSimple>
			<SearchQuery name="q" placeholder="Search jobs" />
			<div className="flex-grow overflow-hidden">
				{!jobs || jobs.length === 0 ? (
					<Hero
						icon={PiIdentificationCard}
						title="No jobs"
						description="Get started by creating a new job."
					>
						<div className="mt-6">
							<LinkButton
								to={'new'}
								variant={'default'}
								size={'lg'}
							>
								<PlusIcon
									className="-ml-0.5 mr-2 h-5 w-5"
									aria-hidden="true"
								/>
								Create New Job
							</LinkButton>
						</div>
					</Hero>
				) : (
					<DataTable
						data={jobs}
						columns={columns}
						totalRows={
							metadata?.pagination?.total_count || jobs.length
						}
						pageSize={DEFAULT_PAGE_SIZE}
						sortProps={{
							initialField: metadata?.ordering?.[0]?.order_by,
							initialOrder:
								metadata?.ordering?.[0]?.order_direction,
						}}
					/>
				)}
			</div>
		</div>
	)
}

// need the generic error boundary
export const ErrorBoundary = GenericErrorBoundary
